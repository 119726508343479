import Default from './default'
import configQ from '../utils/func/configQ'

class Contact extends Default {
	constructor() {
		super('contact')
	}
	mounted() {

        configQ(window.innerWidth)

    }

	resize(w) {

        this.isMobile = document.innerWidth < 900 
		const oldConfig = this.isMobile
        configQ(w)
        if (oldConfig !== this.isMobile) {this.mounted()}

	}
}

export default Contact