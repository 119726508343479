import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import event from 'dom-event'
import { gsap } from "gsap"
import imgReady from '../utils/func/imgReady'



class Project extends Default {
	constructor() {
		super('project')
	}
	mounted() {
        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        this.carouselCells = document.querySelectorAll('.carousel-cell')
        this.carousel = document.querySelector('.carousel')

        this.buttonNext = document.querySelector('.button_next')
        this.buttonPrev = document.querySelector('.button_prev')

        this.prevPagination = undefined
        this.paginationActive = -1
        this.paginationTotale = this.carouselCells.length - 1

        this.paginationWrapperActive = document.querySelector('.pagination_active')
        this.paginationWrapperTotale = document.querySelector('.pagination_totale')

        this.soundButton = document.querySelector('.button_sound')
        this.marquee = document.querySelector('.marquee')
        this.spansMarquee = this.marquee.querySelector('span')

    }

    init() {

        // je place la pagination totale dans le footer
        this.paginationWrapperTotale.textContent = this.paginationTotale + 1

        this.chargeImage(this.carouselCells[1])
        this.chargeImage(this.carouselCells[this.carouselCells.length - 1])
        this.selectImage('next')

        this.addEvents('on')
        this.initInterval()

    }

    addEvents(type) {

        // event au click changement de slide
        event[type](this.buttonNext, 'click', () => {
            clearInterval(this.interval)
            this.selectImage('next')
        })

        event[type](this.buttonPrev, 'click', () => {
            clearInterval(this.interval)
            this.selectImage('prev')
        })

        // event au click button sound je toggle le sound
        event[type](this.soundButton, 'click', this.handleSoundButton)

    }

    initCarousel() {

        // je place la pagination totale dans le footer
        this.paginationWrapperTotale.textContent = this.paginationTotale + 1

        // j'active la slide 1
        this.selectImage('next')

    }

    initInterval() {

        this.interval = setInterval(() => {
            this.selectImage('next')
        }, 3000)

    }


    selectImage(sens) {

        // je save l'ancienne pagination
        this.prevPagination = this.paginationActive

        if(sens == 'next') {

            // je cherche la slide next
            this.paginationActive + 1 <= this.paginationTotale
            ? this.paginationActive = this.paginationActive + 1
            : this.paginationActive = 0

        } else {

            // je cherche la slide prev
            this.paginationActive - 1 >= 0
            ? this.paginationActive = this.paginationActive - 1
            : this.paginationActive = this.paginationTotale

        }

        const prevSlide = this.carouselCells[this.prevPagination]

        if(prevSlide) {

            // si il existe uen ancienne slide je la masque
            prevSlide.classList.contains('active') && prevSlide.classList.remove('active')
            // si l'ancienne slide était une video je la mes en pause
            prevSlide.classList.contains('video') && prevSlide.querySelector('video').pause()

        }
        
        const cellActive = this.carouselCells[this.paginationActive]

        if(cellActive) {

            //lazyload 
            this.chargeImage(cellActive)
            this.carouselCells[this.paginationActive - 1] && this.chargeImage(this.carouselCells[this.paginationActive - 1])
            this.carouselCells[this.paginationActive + 1] && this.chargeImage(this.carouselCells[this.paginationActive + 1])

            // j'affiche la slide active
            cellActive.classList.add('active')

            // si c'est une video j'affiche le boutton de son et je lance la video
            if(cellActive.classList.contains('video')) {

                cellActive.querySelector('video').play()
                this.soundButton.classList.add('active')

            } else {

                this.soundButton.classList.remove('active')
        
            }

            // je remplace la légende 
            if(cellActive.dataset.legend) {

                this.marquee.dataset.text = cellActive.dataset.legend + '                           '
                this.spansMarquee.textContent = cellActive.dataset.legend + '                           '
                console.log(this.spansMarquee.textContent.length / 10)
                this.marquee.setAttribute('style', `--tw: ${this.spansMarquee.textContent.length / 10}s`)

            } else {

                this.marquee.dataset.text = ''
                this.spansMarquee.textContent = ''

            }
            
        }

        // je place la pagination dan sle footer
        this.paginationWrapperActive.textContent = this.paginationActive + 1


        // je fais monter le marquee au niveau de l'image sur mobile
        if(config.isMobile) {

            const height = cellActive.getBoundingClientRect().height / 2
            cellActive.dataset.height = parseInt(height) + 10
            const top = parseInt(window.innerHeight / 2) + parseInt(height) + 10
            // this.marquee.style.top = `${top}px`

            cellActive.querySelector('img') && imgReady(cellActive.querySelector('img'), () => {
                const height = cellActive.getBoundingClientRect().height / 2
                const top = parseInt(window.innerHeight / 2) + parseInt(height) + 10
                // this.marquee.style.top = `${top}px`
            })

        }
    }

    handleSoundButton() { 

        if(!this.soundButton.classList.contains('on')) {

            this.soundButton.classList.add('on')
            this.soundButton.textContent = 'sound on'
            if(this.carouselCells[this.paginationActive]?.classList.contains('video')) this.carouselCells[this.paginationActive].querySelector('video').muted = false

        } else {

            this.soundButton.classList.remove('on')
            this.soundButton.textContent = 'sound off'
            if(this.carouselCells[this.paginationActive]?.classList.contains('video')) this.carouselCells[this.paginationActive].querySelector('video').muted = true


        }

    }

    chargeImage(image_wrapper) {

        const image = image_wrapper.querySelector('img')

        if(image?.dataset.srcset) {

            image.srcset = image.dataset.srcset
            image.removeAttribute('data-srcset')

        } else {

            return

        }
    }

	beforeDestroy() {

        clearInterval(this.interval)
		this.addEvents('off')

    }

	resize(w) {

        this.mid = (window.innerWidth - 40) / 2
		const oldConfig = config.isMobile        
        configQ(w)
        console.log(oldConfig !== config.isMobile)
        if (oldConfig !== config.isMobile) {
            if(!config.isMobile) {this.marquee.style.top = `${window.innerHeight / 3 * 2}px`} 
            document.querySelector('.active').classList.remove('active')
            clearInterval(this.interval)
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Project