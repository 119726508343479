import select from 'dom-select'
import bind from 'es6-class-bind-all'
import config from '../config'
import gsap from 'gsap'

class Nav {
	constructor() {
		bind(this)

		this.cache()
		this.init()
	}
	cache() {

		this.header = select('.header')
        this.burger = select('.burger')
        this.back = select('.back')
        this.navigation = select('.navigation')

	}

	init() {

		if(config.isMobile) {
			
			this.burger.addEventListener('click', this.toggleNavbar)
			this.navigation.addEventListener('click', this.toggleNavbar)

		}

		let oldConfig = config.isMobile   

		 window.addEventListener('resize', () => {
     
			console.log(oldConfig !== config.isMobile, oldConfig , config.isMobile)
			if (oldConfig !== config.isMobile) {

				oldConfig = config.isMobile

				if(!config.isMobile) {

					this.header.classList.contains('open') && this.closeNavBar()
					this.burger.removeEventListener('click', this.toggleNavbar)
					this.navigation.removeEventListener('click', this.toggleNavbar)
	
				} else {
	
					this.burger.addEventListener('click', this.toggleNavbar)
					this.navigation.addEventListener('click', this.toggleNavbar)
	
				}

			}

		 })
        
    }

	toggleNavbar() {

		this.header.classList.contains('open')
        ? this.closeNavBar()
        : this.openNavBar()
        
	}

	openNavBar() {

		this.header.classList.add('open')
		if(config.isMobile) {
			setTimeout(() => {
				this.navigation.style.opacity = 1
				this.navigation.style.pointerEvents = 'auto'
			}, 300)
		}

	}

	closeNavBar() {

		setTimeout(() => {this.header.classList.remove('open')}, 300)
		if(config.isMobile) {
			this.navigation.style.opacity = 0
			this.navigation.style.pointerEvents = 'none'
		}

	}
}

export default Nav