import Contact from './contact'
import Home from './home'
import Informations from './informations'
import Portfolio from './portfolio'
import Project from './project'

const sections = [
	new Home,
	new Informations,
	new Contact,
	new Portfolio,
	new Project

]

export default sections
