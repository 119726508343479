import Default from './default'
import configQ from '../utils/func/configQ'

class Portfolio extends Default {
	constructor() {
		super('portfolio')
	}
	mounted() {

        configQ(window.innerWidth)

    }

	resize(w) {

		configQ()

	}
}

export default Portfolio