import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import event from 'dom-event'
import { gsap } from "gsap"

import Plyr from 'plyr'
import Flickity from 'flickity'




class Home extends Default {
	constructor() {
		super('home')
	}
	mounted() {
        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        console.log('hello home')
        if(!config.isMobile) {
            this.carouselCells = [...document.querySelectorAll('.carousel .carousel-cell')]
            this.carousels = [...document.querySelectorAll('.carousel')]
            this.prevPagination = undefined
            this.paginationActive = 0 
            this.paginationTotale = (this.carouselCells.length / 2) - 1

            this.paginationWrapperActive = document.querySelector('.pagination_active')
            this.paginationWrapperTotale = document.querySelector('.pagination_totale')
            this.legendWrapper = document.querySelector('.legend_wrapper')
        }

        this.soundButton = document.querySelector('.button_sound')

        this.mid = ((window.innerWidth - 40) / 2)

        this.inAction = false

    }

    init() {
        
        this.addEvents('on')
        
        if(!config.isMobile) {

            this.paginationWrapperTotale.textContent = this.paginationTotale + 1
    
            let index = 0
            
            this.carouselCells.forEach((cell, index) => {
                if(cell.dataset.key == 0) {
                    // lazyload
                    this.chargeImage(cell)
                    this.carouselCells[index + (((this.carouselCells.length) / 2) - 1)] && this.chargeImage(this.carouselCells[index + (((this.carouselCells.length) / 2) - 1)])
                    this.carouselCells[index + 1] && this.chargeImage(this.carouselCells[index + 1])
                    //affichage première image
                    this.activeCellNext(cell, index)
                    index++
                }
            })
            this.initCarousel()

        } else {

            this.initCarouselMobile()

        }

    }


    addEvents(type) {

        event[type](document.querySelector('.left_part'), 'click', () => {
            clearInterval(this.interval)
            this.selectImage('prev')
        })
        event[type](document.querySelector('.right_part'), 'click', () => {
            clearInterval(this.interval)
            this.selectImage('next')
        })


    }

    initCarouselMobile() {

        const mainTicker = new Flickity( '#carousel_mobile', {

            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            draggable: true

        })

        const intro = document.querySelector('.intro_mobile_wrapper')
        gsap.to(intro, {
            x: -2000,
            duration: 50
        })

    }


    initCarousel() {

        this.interval = setInterval(() => {
            this.selectImage('next')
        }, 3000)

    }

    selectImage(sens) {

        if(this.inAction) return

        this.inAction = true

        this.prevPagination = this.paginationActive

        if(sens == 'next') {

            // je cherche la slide next
            this.paginationActive + 1 <= this.paginationTotale
            ? this.paginationActive = this.paginationActive + 1
            : this.paginationActive = 0

        } else {

            // je cherche la slide prev
            this.paginationActive - 1 >= 0
            ? this.paginationActive = this.paginationActive - 1
            : this.paginationActive = this.paginationTotale

        }

        let indexR = 0
        // si il existe des anciennes slides je les masque et si l'ancienne slide était une video je la mes en pause
        this.carouselCells.forEach((cell) => {

            if(cell.dataset.key == this.prevPagination && cell.classList.contains('active')) {

                cell.classList.remove('active')
                cell.querySelector('video')?.pause()
                sens == 'next' ? this.removeCellNext(cell, indexR) : this.removeCellPrev(cell, indexR)
                indexR++

            }
        })
        
        let indexA = 0
        // j'affiche la slide active
        this.carouselCells.forEach((cell, index) => {

            if(cell.dataset.key == this.paginationActive) {

                cell.classList.add('active')

                //lazyload
                // this.chargeImage(cell)
                this.carouselCells[index - 1] && this.chargeImage(this.carouselCells[index - 1])
                this.carouselCells[index + 1] && this.chargeImage(this.carouselCells[index + 1])

                sens == 'next' ? this.activeCellNext(cell, indexA) : this.activeCellPrev(cell, indexA)
                indexA++

                 // si c'est une video j'affiche le boutton de son et je lance la video
                if(cell.classList.contains('video')) {

                    cell.querySelector('video').play()
                    this.soundButton.classList.add('active')

                } else {

                    this.soundButton.classList.remove('active')

                }

            }

        })

        //je fais monter le marquee au niveau de l'image sur mobile
        // if(config.isMobile) {

        //     const top = parseInt(window.innerHeight / 2) + parseInt(this.carouselCells[index].dataset.height)
        //     this.marquee.style.top = `${top}px`

        // }
    }

    removeCellPrev(cell) {
        
        cell
        const tl = gsap.timeline()
        .to(cell, {
            duration: 0.7,
            ease: "power2.out",
            left: this.mid
        })

    }

    activeCellPrev(cell, index) {


        this.paginationWrapperActive.textContent = this.paginationActive + 1
        if(index == 0) this.legendWrapper.textContent = cell.dataset.legend

        const tl = gsap.timeline()
        .to(cell, {
            duration: 0,
            left: this.mid * -1,
        })
        .to(cell, {
            duration: 0.7,
            ease: "power2.out",
            left: 0
        })
        .then(() => {
            this.inAction = false
        })

    }

    removeCellNext(cell, index) {
        
        const tl = gsap.timeline()
        .to(cell, {
            duration: 0.7,
            ease: "power3.out",
            left: this.mid * -1
        })

    }

    activeCellNext(cell, index) {

        this.paginationWrapperActive.textContent = this.paginationActive + 1
        if(index == 0) this.legendWrapper.textContent = cell.dataset.legend

        const tl = gsap.timeline()
        .to(cell, {
            duration: 0,
            left: this.mid,
        })
        .to(cell, {
            duration: 0.7,
            ease: "power3.out",
            left: 0
        })
        .then(() => {
            this.inAction = false
        })

    }

    chargeImage(image_wrapper) {

        const image = image_wrapper.querySelector('img')
        if(image?.dataset.srcset) {
            image.srcset = image.dataset.srcset
            image.removeAttribute('data-srcset')
        } else {
            return
        }
        
    }

	beforeDestroy() {

        clearInterval(this.interval)
		this.addEvents('off')
        this.flkty && this.flkty.destroy()

    }

	resize(w) {

        this.mid = (window.innerWidth - 40) / 2
		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            clearInterval(this.interval)
            this.addEvents('off')
            this.flkty && this.flkty.destroy()
            this.mounted()
        }

	}
}

export default Home