import Default from './default'
import configQ from '../utils/func/configQ'




class Informations extends Default {
	constructor() {
		super('informations')
	}
	mounted() {

        configQ(window.innerWidth)

    }

	resize(w) {

		configQ()

	}
}

export default Informations