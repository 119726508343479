const imgReady = (a, b) => {
    let i = null,
      f = () => {
        if (a.complete || (a.readyState === 4) || (a.readyState === 'complete')) {
            console.log('image compl')
          clearInterval(i)
          b(a)
          return !0
        }
        return !1
    };
    if (!f()) {
      i = setInterval(() => {
        f()
      }, 150)
    }
}
export default imgReady